import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getInscription } from "../../service/api/api-methods";
import { InscriptionResponse } from "../../@types/api-response-data.type";
import style from "../../styles/home-page.module.css";
import Loader from "../../component/Loader";
import DoginalsContentRender from "./DoginalsContentRender";
import style2 from "../../styles/inscription-page.module.css";
import DoginalsInfo from "./DoginalsInfo";
import HistoryRender from "../../component/HistoryRender";
import { FormatNumber } from "../../utils/app-function";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import NotFound from "../../component/NotFound";

export default function Doginalspage() {
  const { id } = useParams();

  const [inscriptionData, setInscriptionData] =
    useState<InscriptionResponse | null>(null);

  const [Loading, setLoading] = useState(true);

  const fetchInscription = async () => {
    try {
      setLoading(true);
      setInscriptionData(null);
      const dataInscription = await getInscription(id!);
      setInscriptionData(dataInscription);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchInscription();
  }, [id]);

  return (
    <>
      <Header />
      {Loading ? (
        <div className={style.FullWidthLoading}>
          <Loader />
        </div>
      ) : inscriptionData ? (
        <div className={style2.InscriptionMenu}>
          <div className={style2.InscriptionNumberTag + " " + style2.ShowMb}>
            <h3>Shibscription</h3>
            <span>{FormatNumber(inscriptionData.inscriptionNumber)}</span>
          </div>
          <div className={style2.inscriptionInfo}>
            <DoginalsContentRender
              contentType={inscriptionData.contentType}
              id={inscriptionData?.id}
            />
            <DoginalsInfo inscription={inscriptionData} />
          </div>
          <HistoryRender id={id!} />
        </div>
      ) : (
        <NotFound />
      )}
      <Footer />
    </>
  );
}
