import React, { useEffect, useState } from "react";
import { FetchContent, getInscriptions } from "../service/api/api-methods";
import { InscriptionResponse } from "../@types/api-response-data.type";
import InscriptionHomeCard from "../component/InscriptionHomeCard";
import style from "../styles/home-page.module.css";
import mime from "mime";
import Tabs from "../component/Tabs";
import Loader from "../component/Loader";
import { usePagination } from "../hooks/usePagination";
import { Pagination } from "@mui/material";
import { LIMITPERPAGE } from "../utils/consatant";
import Prism from "prismjs";
import "prismjs/components/prism-typescript";
import "prismjs/themes/prism-okaidia.css"; // You can change the theme here
import Header from "../component/Header";
import Footer from "../component/Footer";
import { FormatNumber } from "../utils/app-function";
import NotFound from "../component/NotFound";

export default function HomePage() {
  const [inscriptionsData, setinscriptionData] = useState<
    InscriptionResponse[] | null
  >(null);

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex,
    displayPage,
    setTotalPage,
  ] = usePagination();

  const [loading, setLoading] = useState(true);

  const [selectedContent, setSelectedContent] = useState("");

  const [textData, setTextData] = useState<{ data: string; id: string }[]>([]);
  const [totalData, setTotalData] = useState(0);
  const FetchInscriptions = async () => {
    try {
      setLoading(true);
      setinscriptionData(null);
      //@ts-ignore
      setTotalPage(0);
      setTotalData(0);

      const Inscriptions = await getInscriptions(
        LIMITPERPAGE,
        LIMITPERPAGE * ((currentPageIndex as number) - 1),
        selectedContent
      );
      setTotalData(Inscriptions.total);
      setinscriptionData(Inscriptions.results);

      const TotalPage = Math.round(Inscriptions.total / LIMITPERPAGE);
      //@ts-ignore
      setTotalPage(TotalPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getContent = async () => {
    for (const inscriptions of inscriptionsData!) {
      const isText = mime.getExtension(inscriptions.contentType);

      if (isText !== "txt" && isText !== "js") continue;

      const data = (await FetchContent(inscriptions.id)) as string;

      setTextData((prevTextData) => [
        ...prevTextData,
        { id: inscriptions.id, data: data },
      ]);
    }
  };

  useEffect(() => {
    if (inscriptionsData) getContent();
  }, [inscriptionsData]);

  useEffect(() => {
    if (selectedContent) FetchInscriptions();
  }, [selectedContent, currentPageIndex]);

  useEffect(() => {
    if (typeof displayPage !== "number") displayPage(1);
  }, [selectedContent]);

  // useEffect(() => {
  //   if (!selectedContent) setSelectedContent("all");
  // }, []);

  useEffect(() => {
    Prism.highlightAll();
  }, [textData]);

  const Items = [
    "All",
    "TEXT",
    "PNG",
    "JPEG",
    "HTML",
    "Javascript",
    "SVG",
    "GIF",
    "3D",
    "Video",
    "Audio",
  ];
  return (
    <>
      <Header />
      <div className={style.homePage}>
        <Tabs setSelectedTab={setSelectedContent} items={Items} />

        {loading ? (
          <div className={style.FullWidthLoading}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={style.countDisplay}>
              <span>
                Found total <strong>{FormatNumber(totalData)}</strong>{" "}
                {selectedContent === "all" ? "" : selectedContent} Shibscription
              </span>
            </div>
            <div className={style.InscriptionCards}>
              {!inscriptionsData?.length ? (
                <NotFound />
              ) : (
                inscriptionsData?.map((e, _) => {
                  const textData_ = textData?.find((a) => a.id === e.id)?.data;
                  return (
                    <div key={_}>
                      <InscriptionHomeCard
                        contentType={e.contentType}
                        date={e.time}
                        id={e.id}
                        number={e.inscriptionNumber}
                        text={textData_}
                      />
                    </div>
                  );
                })
              )}
            </div>{" "}
          </>
        )}
        <Pagination
          shape="rounded"
          color="secondary"
          count={totalPages as number}
          onChange={(_, value) => {
            //@ts-ignore
            displayPage(value as number);
          }}
        />
      </div>
      <Footer />
    </>
  );
}
