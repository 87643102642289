import React, { useEffect, useState } from "react";
import style from "../styles/inscription-page.module.css";
import { inscriptionHistoryResponse } from "../@types/api-response-data.type";
import { getInscriptionHistory } from "../service/api/api-methods";
import {
  FormatNumber,
  GetDate,
  RefTxidToChainSo,
  RefWalletToOrdifind,
  TrimText,
} from "../utils/app-function";
interface HistoryProps {
  id: string;
}
export default function HistoryRender(props: HistoryProps) {
  const [inscriptionHistory, setInscriptionHistory] = useState<
    inscriptionHistoryResponse[] | null
  >(null);

  const FetchHistory = async () => {
    try {
      const hData = await getInscriptionHistory(props.id, 50, 0);
      setInscriptionHistory(hData);
    } catch (error) {}
  };

  useEffect(() => {
    FetchHistory();
  }, [props.id]);
  if (!inscriptionHistory?.length) return <></>;
  return (
    <>
      <div className={style.HistoryTitle}>
        <h3>Activities</h3>
      </div>
      <div className={style.HistoryRender}>
        <table>
          <thead>
            <th>Block</th>
            <th>From</th>
            <th>To</th>
            <th>Date</th>
            <th>TXID</th>
          </thead>
          <tbody>
            {inscriptionHistory?.map((e, _) => {
              return (
                <>
                  <tr key={_}>
                    <td>
                      <div className={style.text_history}>
                        <span>{FormatNumber(e.block)}</span>
                      </div>
                    </td>
                    <td>
                      <div className={style.text_history}>
                        <span>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={RefWalletToOrdifind(e.from)}
                          >
                            {TrimText(e.from)}
                          </a>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={style.text_history}>
                        <span>
                          <a
                            href={RefWalletToOrdifind(e.to)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {TrimText(e.to)}
                          </a>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={style.text_history}>
                        <span>{GetDate(e.time)}</span>
                      </div>
                    </td>
                    <td>
                      <div className={style.text_history}>
                        <span>
                          <a
                            href={RefTxidToChainSo(e.txid)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {TrimText(e.txid)}
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>{" "}
    </>
  );
}
