import axios from "axios";

class APIServer {
  url: string;

  constructor(_url: string) {
    this.url = _url;
  }

  async getRequest(route: string) {
    const url = `${this.url}/${route}`;
    const data = await axios.get(url);

    if (!data.data || data.data.code !== 200)
      throw new Error("Faild to get response");

    return data.data.data;
  }
  async postRequest(route: string, payload: any) {
    const url = `${this.url}/${route}`;
    const data = await axios.post(url, payload);

    if (!data.data || data.data.code !== 200)
      throw new Error("Faild to get response");

    return data.data.data;
  }
}

export default APIServer;
