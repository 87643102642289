import React, { useEffect, useState } from "react";
import style from "../styles/component.module.css";
import { useLocation, useNavigate } from "react-router-dom";
interface TabProps {
  items: string[];
  setSelectedTab: React.Dispatch<React.SetStateAction<any>>;
}
export default function Tabs(props: TabProps) {
  const [SelectTab, setSelectTab] = useState(-1);
  const location = useLocation(); // Get the current URL location
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const contentTypeValue = searchParams.get("contentType");
    const ItemIndex = props.items.findIndex(
      (a) => a.toLowerCase() === contentTypeValue?.toLowerCase()
    );
    if (ItemIndex === -1) {
      props.setSelectedTab(props.items[0].toLowerCase());
      setSelectTab(0);
      return;
    }

    setSelectTab(ItemIndex);

    props.setSelectedTab(props.items[ItemIndex].toLowerCase());
  }, [location]);

  return (
    <div className={style.Tab}>
      {props.items.map((e, i) => {
        return (
          <span
            style={{ backgroundColor: SelectTab === i ? "#3c40a4" : "" }}
            onClick={() => {
              setSelectTab(i);
              props.setSelectedTab(props.items[i].toLowerCase());
              searchParams.set("contentType", props.items[i].toLowerCase());
              navigate(`?${searchParams.toString()}`);
            }}
            key={i}
          >
            {e}
          </span>
        );
      })}
    </div>
  );
}
