import React from "react";
import style from "../styles/component.module.css";
import SearchBar from "./SearchBar";
import { toast } from "react-toastify";
export default function Header() {
  return (
    <div className={style.Header}>
      <div className={style.nav}>
        <div className={style.Brand}>
          <a href="/">
            <img
              src="https://ordifind.com/_next/static/media/logo.1c439e17.png"
              alt="ordifind"
            />
            <div className={style.Abname}>
              <span>Doginals Explorer</span>
            </div>
          </a>
        </div>
        <SearchBar />
        <div className={style.WalletConnectbtn}>
          <button
            style={{ cursor: "pointer" }}
            onClick={() => {
              toast.info("Soon");
            }}
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  );
}
