import React from "react";
import style from "../styles/component.module.css";
export default function NotFound() {
  return (
    <div className={style.NotFound}>
      <div className={style.NotFoundText}>
        <h3>Not Found :)</h3>
      </div>
    </div>
  );
}
