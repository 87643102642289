import React, { useEffect, useState } from "react";
import mimetype from "mime";
import { FetchContent, getContentAPI } from "../service/api/api-methods";
import Loader from "./Loader";
import prims from "prismjs";
import axios from "axios";
interface props {
  id: string;
  contentType: string;
}
export default function DoginalsContentRenders(props: props) {
  const [text, setText] = useState("");
  const source = axios.CancelToken.source();

  const fetchText = async () => {
    const textData = await FetchContent(props.id);
    setText(textData);
  };

  useEffect(() => {
    const contentType = mimetype.getExtension(props.contentType);
    if (!contentType) return;
    if (["txt", "js"].includes(contentType)) fetchText();
    // Re-highlight on every render

    return () => {
      source.cancel("User close!");
    };
  }, [props.id]);
  useEffect(() => {
    prims.highlightAll();
  }, [text]);
  return (
    <>
      {(() => {
        const contentType = mimetype.getExtension(props.contentType);
        if (
          contentType &&
          ["png", "webp", "gif", "jpeg", "svg"].includes(contentType)
        ) {
          return <img src={getContentAPI(props.id)} alt="inscription" />;
        } else if (contentType === "glb") {
          return (
            //@ts-ignore
            <model-viewer
              style={{ width: "100%", height: "100%" }}
              src={getContentAPI(props.id)}
              alt="A 3D model"
              auto-rotate={true}
              camera-controls
              ar
            />
          );
        } else if (contentType === "html") {
          return (
            <iframe
              frameBorder={0}
              sandbox="allow-scripts"
              style={{ width: "100%", borderRadius: "12px", height: "95%" }}
              loading="lazy"
              src={getContentAPI(props.id)}
            ></iframe>
          );
        } else if (contentType === "js") {
          return !text ? (
            <Loader />
          ) : (
            <pre
              style={{ backgroundColor: "transparent" }}
              className="language-js"
            >
              <code className="language-js" style={{ whiteSpace: "pre-wrap" }}>
                {text}
              </code>
            </pre>
          );
        } else if (contentType === "mp4") {
          return (
            <video
              autoPlay={true}
              controls
              src={getContentAPI(props.id)}
            ></video>
          );
        } else if (contentType === "mpga") {
          return (
            <audio controls autoPlay={true} src={getContentAPI(props.id)} />
          );
        }
        return (
          <span style={{ padding: "10px", boxSizing: "border-box" }}>
            {!text ? (
              <Loader />
            ) : typeof text === "object" ? (
              JSON.stringify(text)
            ) : (
              text
            )}
          </span>
        );
      })()}
    </>
  );
}
