import moment from "moment";
export enum ValidSearchMethods {
  "Search Shibscription by ID",
  "Search Shibscription by Number",
}
export const FormatNumber = (number: number, decimal: number = 0) =>
  number.toLocaleString(undefined, { maximumFractionDigits: decimal });

export const TimeFromNow = (time: number) => moment(time * 1000).fromNow();

export const GetDate = (time: number) =>
  moment(time * 1000).format("MMMM Do YYYY, h:mm:ss A");

export const TrimText = (text: string) => {
  const textA = text.slice(0, 5);
  const textB = text.slice(-5);
  return `${textA}...${textB}`;
};

export const FindSearchType = (text: string) => {
  console.log(text.length);
  if (text.length === 66) {
    return ValidSearchMethods["Search Shibscription by ID"];
  } else if (!isNaN(Number(text))) {
    return ValidSearchMethods["Search Shibscription by Number"];
  }

  return undefined;
};

export const RefTxidToChainSo = (hash: string) =>
  `https://chain.so/tx/DOGE/${hash}`;
export const RefWalletToOrdifind = (wallet: string) =>
  `https://ordifind.com/balance/${wallet}`;
