import React, { useState } from "react";
import style from "../styles/home-page.module.css";
import mimetype from "mime";
import { FormatNumber, TimeFromNow } from "../utils/app-function";
import { getContentAPI } from "../service/api/api-methods";
import Loader from "./Loader";
import { useNavigate } from "react-router";
interface InscriptionHomeCardProps {
  contentType: string;
  id: string;
  number: number;
  date: number;
  text?: string;
}

export default function InscriptionHomeCard(props: InscriptionHomeCardProps) {
  const Navigate = useNavigate();
  return (
    <div
      onClick={() => Navigate(`/doginals/${props.number}`)}
      className={style.InscriptionHomeCard}
    >
      <div className={style.InscriptionHomeCardTag}>
        <span>{mimetype.getExtension(props.contentType)}</span>
      </div>

      <div className={style.InscriptionCardDataRender}>
        {(() => {
          const contentType = mimetype.getExtension(props.contentType);
          if (
            contentType &&
            ["png", "webp", "gif", "jpeg", "svg"].includes(contentType)
          ) {
            return <img src={getContentAPI(props.id)} alt="inscription" />;
          } else if (contentType === "glb") {
            return (
              //@ts-ignore
              <model-viewer
                style={{ width: "100%", height: "100%" }}
                src={getContentAPI(props.id)}
                alt="A 3D model"
                auto-rotate={true}
                camera-controls
                ar-status="not-presenting"
              />
            );
          } else if (contentType === "html") {
            return (
              <iframe
                frameBorder={0}
                scrolling="off"
                width={"100%"}
                sandbox="allow-script"
                loading="lazy"
                src={getContentAPI(props.id)}
              ></iframe>
            );
          } else if (contentType === "js") {
            return !props.text ? (
              <Loader />
            ) : (
              <pre
                style={{ backgroundColor: "transparent" }}
                className="language-js"
              >
                <code
                  className="language-js"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {props.text.slice(0, 100)}
                </code>
              </pre>
            );
          } else if (contentType === "mp4") {
            return (
              <video
                autoPlay={false}
                controls
                src={getContentAPI(props.id)}
              ></video>
            );
          } else if (contentType === "mpga") {
            return <audio controls src={getContentAPI(props.id)} />;
          }
          return (
            <span style={{ padding: "10px" }}>
              {!props.text ? (
                <Loader />
              ) : typeof props.text! === "object" ? (
                JSON.stringify(props.text!)
              ) : (
                props.text!
              )}
            </span>
          );
        })()}
      </div>
      <div className={style.InscriptionInfo}>
        <h6>#{FormatNumber(props.number)}</h6>
        <span>{TimeFromNow(props.date)}</span>
      </div>
    </div>
  );
}
