import React from "react";
import style from "../../styles/inscription-page.module.css";
import { InscriptionResponse } from "../../@types/api-response-data.type";
import {
  FormatNumber,
  GetDate,
  RefTxidToChainSo,
  RefWalletToOrdifind,
  TrimText,
} from "../../utils/app-function";
import mime from "mime";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
interface infoProps {
  inscription: InscriptionResponse;
}

export default function DoginalsInfo(props: infoProps) {
  const copyText = (text: string) => {
    window.navigator.clipboard.writeText(text);
    toast.success("Copied !");
  };
  return (
    <div className={style.DoginalsInfo}>
      <div className={style.InscriptionNumberTag}>
        <h3>Shibscription</h3>
        <span>{FormatNumber(props.inscription.inscriptionNumber)}</span>
      </div>

      <div className={style.DoginalsInfoContentBox}>
        <div className={style.DoginalsInfo_text}>
          <h5>
            ID
            <i onClick={() => copyText(props.inscription.id)}>
              <MdContentCopy />
            </i>
          </h5>
          <span>{props.inscription.id}</span>
        </div>
        {props.inscription.delegation_id && (
          <div className={style.DoginalsInfo_text}>
            <h5>
              Delegation ID
              <i onClick={() => copyText(props.inscription.delegation_id!)}>
                <MdContentCopy />
              </i>
            </h5>
            <span>{props.inscription.delegation_id}</span>
          </div>
        )}
        <div className={style.DoginalsInfo_text}>
          <h5>
            Owned By
            <i onClick={() => copyText(props.inscription.owner)}>
              <MdContentCopy />
            </i>
          </h5>
          <span>
            <a
              href={RefWalletToOrdifind(props.inscription.owner)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.inscription.owner}
            </a>
          </span>
        </div>
        <div className={style.DoginalsInfo_text}>
          <h5>File Type</h5>
          <span>
            {mime.getExtension(props.inscription.contentType)?.toUpperCase() ||
              props.inscription.contentType}
          </span>
        </div>
        <div className={style.DoginalsInfo_text}>
          <h5>Created On</h5>
          <span>{GetDate(props.inscription.time)}</span>
        </div>
        <div className={style.DoginalsInfo_text}>
          <h5>Creation Block</h5>
          <span>{FormatNumber(props.inscription.block)}</span>
        </div>
        <div style={{ cursor: "pointer" }} className={style.DoginalsInfo_text}>
          <h5>
            Location{" "}
            <i onClick={() => copyText(props.inscription.location)}>
              <MdContentCopy />
            </i>
          </h5>{" "}
          <span>{props.inscription.location}</span>
        </div>{" "}
        <div style={{ cursor: "pointer" }} className={style.DoginalsInfo_text}>
          <h5>Offset</h5>
          <span>{props.inscription.offset}</span>
        </div>
        <div
          style={{ borderBottom: "none" }}
          className={style.DoginalsInfo_text}
        >
          <h5>
            Creation TXID{" "}
            <i onClick={() => copyText(props.inscription.txid)}>
              <MdContentCopy />
            </i>
          </h5>
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={RefTxidToChainSo(props.inscription.id.slice(0, -2))}
            >
              {props.inscription.id.slice(0, -2)}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
