import { useState } from "react";
import { LIMITPERPAGE } from "../utils/consatant";

export const usePagination = () => {
  const [totalPages, setTotalPage] = useState(0);
  const [startPageIndex, setStatrPageIndex] = useState(0);
  const [endPageIndex, setEndPageIndex] = useState(LIMITPERPAGE - 1);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  //function
  const displayPage = (pageNo: number) => {
    setCurrentPageIndex(pageNo);

    let end_page_index = LIMITPERPAGE * pageNo - 1;

    let start_page_index = LIMITPERPAGE * pageNo - LIMITPERPAGE;

    setStatrPageIndex(start_page_index);

    if (end_page_index > totalPages) {
      setEndPageIndex(totalPages - 1);
    } else {
      setEndPageIndex(end_page_index);
    }
  };

  return [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex,
    displayPage,
    setTotalPage,
  ];
};
