import React, { useEffect, useState } from "react";
import style from "../styles/component.module.css";
import { CiSearch } from "react-icons/ci";
import { FindSearchType, ValidSearchMethods } from "../utils/app-function";
import { useNavigate } from "react-router";

export default function SearchBar() {
  const navigation = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [SearchMethods, setSearchMethods] = useState<
    ValidSearchMethods | undefined
  >(undefined);

  const FindSearchMethods = () => {
    if (!searchValue.length) return;

    const SearchMethods = FindSearchType(searchValue);

    setSearchMethods(SearchMethods);
  };

  useEffect(() => {
    FindSearchMethods();
  }, [searchValue]);

  const HandleOnClickEvent = () => {
    if (!searchValue) return;
    switch (SearchMethods) {
      case 0:
        console.log(`called`);
        navigation(`/doginals/${searchValue}`);
        break;
      case 1:
        console.log(`called`);
        navigation(`/doginals/${searchValue}`);
        break;
      default:
        break;
    }
    setSearchValue("");
  };

  return (
    <div className={style.SearchBar}>
      <span>
        <CiSearch />
      </span>
      <input
        onChange={({ target }) => setSearchValue(target.value)}
        value={searchValue}
        autoComplete="off"
        spellCheck="false"
        type="text"
        placeholder="Search doginals..."
        name="text"
        id="#"
      />
      {searchValue && (
        <div className={style.SearchResult}>
          {SearchMethods !== undefined ? (
            <div className={style.SearchResultData}>
              <h4>{ValidSearchMethods[SearchMethods]}</h4>
              <span onClick={HandleOnClickEvent}>{searchValue}</span>
            </div>
          ) : (
            <div className={style.SearchResultNotFound}>
              <span>Reasult not found !</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
