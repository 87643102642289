import React from "react";
import style from "../styles/component.module.css";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  return (
    <div className={style.Footer}>
      <div className={style.FooterItem}>
        <div className={style.CreatorTag}>
          <span>
            Made by{" "}
            <a href="https://x.com/lazodoge" target="_blank">
              @lazodoge
            </a>{" "}
            with ❤️
          </span>
        </div>
        <div className={style.Socials}>
          <a href="https://x.com/ordifind" target="_blank">
            <span>
              <FaXTwitter />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
