import React, { useEffect, useState } from "react";
import style from "../../styles/inscription-page.module.css";
import mimetype from "mime";
import "prismjs/components/prism-typescript";
import "prismjs/themes/prism-okaidia.css"; // You can change the theme here
import DoginalsContentRenders from "../../component/DoginalsContentRender";

interface props {
  id: string;
  contentType: string;
}

export default function DoginalsContentRender(props: props) {
  const [contentType, setcontentType] = useState("");

  useEffect(() => {
    const contentType = mimetype.getExtension(props.contentType);
    if (!contentType) return;
    setcontentType(contentType);
  }, [props.id]);

  return (
    <div
      style={{ height: ["txt", "js"].includes(contentType) ? "auto" : "" }}
      className={style.InscriptionContentRender}
    >
      <DoginalsContentRenders id={props.id} contentType={props.contentType} />
    </div>
  );
}
