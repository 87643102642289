import axios from "axios";
import APIServer from "./api-server";

const URL = "https://inscription.ordifind.com";
const CDN = "https://cdn.ordifind.com";
const API = new APIServer(URL);

export const getInscriptions = async (
  limit: number,
  offset: number,
  contentType: string = "all"
) => {
  return await API.getRequest(
    `inscriptions?limit=${limit}&offset=${offset}&contentType=${contentType}`
  );
};

export const getInscription = async (id: string | number) => {
  return await API.getRequest(`inscription/${id}`);
};

export const getContentAPI = (id: string) => `${CDN}/content/${id}`;

export const FetchContent = async (id: string) => {
  try {
    return (await axios.get(getContentAPI(id))).data;
  } catch (error) {
    return "error";
  }
};

export const getInscriptionHistory = async (
  id: string,
  limit: number,
  offset: number
) => {
  return await API.getRequest(
    `history/inscription/${id}?limit=${limit}&offset=${offset}`
  );
};
