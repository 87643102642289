import React from "react";
import HomePage from "./pages/home";
import { BrowserRouter as _, Route, Routes } from "react-router-dom";
import Doginalspage from "./pages/[doginals]/Doginalspage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <main>
      <Routes>
        <Route path="/doginals/:id" element={<Doginalspage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <ToastContainer theme="dark" />
    </main>
  );
}

export default App;
